import React from "react";
import * as styles from "./header.module.scss"
import {Link} from "gatsby";

export default function Header() {
    return (
        <header className={styles.header}>
            <p className={styles.logo}>
                <Link className={styles.logoLink} to={"/"}>Home</Link>
            </p>
            <nav>
                <ul className={styles.navList}>
                    <li><Link className={styles.navListItem} to={"/portfolio"}>Portfolio</Link></li>
                    <li><Link className={styles.navListItem} to={"/contact"}>Contact</Link></li>
                </ul>
            </nav>
        </header>
    )
}