import React from "react";
import * as styles from "./footer.module.scss"
import {graphql, useStaticQuery} from "gatsby";

export default function Footer(){
    const { site } = useStaticQuery(graphql`
        query {
            site {
                siteMetadata {
                    author
                }
            }
        }
    `)

    return (
        <footer className={styles.footer}>
            <p>Created by <a href={"https://fwtn.be"} rel={"noreferrer"} target="_blank">{site.siteMetadata.author}</a> © 2021</p>
        </footer>
    )
};
