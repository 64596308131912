import React from "react";
import Header from "./header";
import Footer from "./footer";
import * as styles from "./layout.module.scss"
import "./default.scss"
import {Helmet} from "react-helmet";

export default function Layout(props) {
    return (
        <div className={styles.container}>
            <Helmet>
                <link rel="preconnect" href="https://fonts.gstatic.com" />
                <link href="https://fonts.googleapis.com/css2?family=Anton&family=Work+Sans:wght@300&display=swap" rel="stylesheet" />
            </Helmet>
            <div className={styles.content}>
                <Header />
                <main className={styles.main}>
                    {props.children}
                </main>
            </div>
            <Footer />
        </div>
    )
}